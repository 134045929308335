<template>  
<!-- logged_user.privilegio == 2 ? 'Cobradores' : 'Usuarios' => cambiado a => logged_user.privilegio == 2 ? 'Cobradores' : 'Cobradores' -->

  <v-container>
    <v-card elevation="0" class="d-flex mb-2 justify-center" style="border-radius: 24px 24px 8px 8px;;">
      <v-card-title 
        class="pa-4 px-6 grey--text text--darken-3 font-weight-bold text-uppercase" 
        :style="($vuetify.breakpoint.xs ? 'font-size: 18px;' : 'font-size: 20px;')"
      >
        {{ logged_user.privilegio == 2 ? 'Cobradores' : 'Admins/Cobradores' }} - agregar o editar
      </v-card-title>
    </v-card>

    <DataTable
      id="usuario-table"
      :title="`${logged_user.privilegio == 2 ? 'Cobradores' : 'Cobradores'}`"
      :subtitle="`${logged_user.privilegio == 2 ? 'Cobradores' : 'Cobradores'} registrados`"
      :headers="headers"
      :items="usuarioTable.items"
      :loading="tableLoading"
      :toolbarActions="true"
      :toolbarTitle="false"
      :sortBy="['rol_user', 'estatus']"
      :sortDesc="[[true, false], [true, false]]"
      showItemPropToDelete="nombre+apellidos"
      multi-sort
      @editItem="usuarioTable.editItem"
      @deleteItem="usuarioDelete"
    >
      <template slot="toolbarActions">
        <!-- Para que un ref tome efecto el nodo debe existir en el DOM (tambien se toma si esta oculto), 
        en este caso los componentes dentro de Dialog no existen hasta que la accion usuarioTable.dialog 
        es true -->
        <v-form ref="form" v-model="validFormUsuario">
          <Dialog
            useToolbarStyle
            v-model="usuarioTable.dialog"
            :btnText="`Agregar ${logged_user.privilegio == 2 ? 'Cobrador' : 'Usuario'}`"
            :title="`${usuarioTable.formTitle} ${usuarioTable.editedItem.privilegio == 2 ? 'Administrador' : usuarioTable.editedItem.privilegio == 3 ? 'Cobrador' : logged_user.privilegio == 2 ? 'Cobrador' : 'Usuario'}`"
            :subtitle="usuarioTable.isEditable ? usuarioTable.editedItem.nombre + ' ' + usuarioTable.editedItem.apellidos : ''"
            :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
            @onCloseToolbarBtn="usuarioTable.dialog = false"
          >
            <template slot="content">
                <div v-if="usuarioTable.isEditable && usuarioTable.editedItem.user_logs" class="my-2"> 
                  <b>
                    {{ `${usuarioTable.editedItem.privilegio == 2 ? 'Administrador' : usuarioTable.editedItem.privilegio == 3 ? 'Cobrador' : logged_user.privilegio == 2 ? 'Cobrador' : 'Usuario'}` }}
                    capturado por: 
                  </b>
                  {{ usuarioTable.editedItem.user_logs.nombre }} 
                  {{ usuarioTable.editedItem.user_logs.apellidos }},
                  el
                  {{ $dayjs(usuarioTable.editedItem.user_logs.fecha_log).format("DD/MMM/YYYY [a las] h:mm a.") }}
                </div>

                <v-row :class="usuarioTable.isEditable ? '' : ''">
                  <v-col 
                    v-show="logged_user.privilegio == 0 || logged_user.privilegio == 1" 
                    cols="12" sm="6" :md="'6'"
                  > 
                    <v-select
                      :label="`${usuarioTable.isEditable ? 'Rol actual' : 'Asignar rol'}`"
                      :rules="[(logged_user.privilegio == 0 || logged_user.privilegio == 1) ? FormValidations.required : true]"
                      v-model="usuarioTable.editedItem.privilegio"
                      :items="rolesSelect"
                      :disabled="usuarioTable.isEditable"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.nombre"
                      :rules="[FormValidations.required]"
                      label="Nombre"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.apellidos"
                      :rules="[FormValidations.required]"
                      label="Apellidos"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.tel"
                      label="Teléfono"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.domicilio"
                      label="Domicilio"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.colonia"
                      label="Colonia"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.ciudad"
                      label="Ciudad"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.localidad"
                      label="Localidad"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.cp"
                      label="C.P."
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.pais"
                      label="Pais"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.estado"
                      label="Estado"
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" :md="'6'"> 
                    <VTextField
                      v-model="usuarioTable.editedItem.email"
                      :rules="[FormValidations.required, FormValidations.email]"
                      label="Correo electrónico"
                      hint="Se usará para ingresar al sistema"
                      validate-on-blur
                      persistent-hint
                    ></VTextField>
                  </v-col>

                  <v-col cols="12" sm="6" :md="'6'"> 
                    <VTextField
                      v-model="contrasena"
                      :rules="[usuarioTable.isEditable ? true : FormValidations.required]"
                      :label="`${ usuarioTable.isEditable ? 'Reestablecer contraseña (opcional)' : 'Contraseña' }`"
                      :placeholder="`${ usuarioTable.isEditable ? 'Reestablecer (opcional)' : '' }`"
                      :hint="`${ usuarioTable.isEditable ? '' : 'Se usará para ingresar al sistema' }`"
                      :append-icon="showpwd ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showpwd ? 'text' : 'password'"
                      @click:append="showpwd = !showpwd"
                      persistent-hint
                    ></VTextField>
                  </v-col>

                  <v-col 
                    v-if="usuarioTable.isEditable" 
                    class="pb-0"
                    cols="12" sm="6" md="6"
                  > 
                    <v-switch
                      v-model="usuarioTable.editedItem.estatus"
                      color="red darken-3"
                      :prepend-icon="`${usuarioTable.editedItem.estatus ? 'mdi-account-off' : 'mdi-account-check'}`"
                      :label="`${usuarioTable.editedItem.estatus ? 'Estatus: baja' : 'Estatus: alta'}`"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6"> 
                    <Button
                      v-show="usuarioTable.isEditable && usuarioTable.editedItem.privilegio == 3"
                      color="#efefef"
                      @click="goToClientesAsociados"
                    >
                      Ver clientes asociados
                    </Button>
                  </v-col>
                </v-row>
            </template>

            <template slot="actions">
              <!-- <Button text @click="usuarioTable.close">Cancelar</Button> -->
                
              <Button 
                text 
                :disabled="!validFormUsuario || usuarioTable.disableActionBtn" 
                :loading="tableLoading"
                @click="usuarioSave"
              >
                Guardar
              </Button>
            </template>
          </Dialog> 
        </v-form>
      </template>
    </DataTable>


    <Dialog
      useToolbarStyle
      v-model="showDeleteInconsistencyDialog"
      :btnText="``"
      :title="`No se ha podido eliminar al usuario`"
      :subtitle="``"
      :fullscreen="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm"
      @onCloseToolbarBtn="showDeleteInconsistencyDialog = false"
    >
      <template slot="content">
        <v-card-title class="justify-center">
          <span class="headline">
            El siguiente registro no se pudo eliminar:
          </span>
        </v-card-title>

        <div class="mb-4 font-weight-bold" style="text-align: center; font-size: 24px;">
          {{ `${userToDelete.nombre} ${userToDelete.apellidos || ''}` }}
        </div>

        <div class="ml-4">
          <div class="grey--text text--darken-3 mt-6" style="font-size: 17px; vertical-align: bottom;">
            El usuario no se pudo eliminar porque tiene clientes asignados. Para proceder a su eliminación 
            primero se deberán asignar sus clientes relacionados a un cobrador que esté activo.
          </div>
          <div class="grey--text text--darken-3 mt-6" style="font-size: 17px; vertical-align: bottom;">
            El botón "<b>IR A CLIENTES</b>" lo redirigirá a la vista de Clientes para proceder a realizar esta acción.
            Una vez completado este paso vuelva a la vista de Cobradores y proceda a eliminar al usuario.
          </div>
        </div>
      </template>

      <template slot="actions">
        <!-- <Button text @click="showDeleteInconsistencyDialog = false">Cancelar</Button> -->
          
        <Button 
          text 
          @click="onDeleteInconsistencyGoToClientsView"
        >
          Ir a Clientes
        </Button>
      </template>
    </Dialog> 

    <vue-element-loading 
      :active="fullPageLoading" 
      text="Procesando..." 
      :text-style="{ color: '#333', fontSize: '20px' }" 
      :is-full-screen="true"
    />

  </v-container>

</template>

<script>
import Button from "@/components/ui/Button";
import Dialog from "@/components/ui/Dialog";
import DataTable from "@/components/ui/DataTable";
import useDataTable from "@/composables/useDataTable";
import { getCurrentInstance, ref, watch, computed } from "vue";
import { APP_DEBUG_MODE as APP_DEBUG } from "@/utils/Constants";
import { addUserLogToSendToDB, btoaEncodeData } from "@/utils/Functions";
import FormValidations from "@/utils/FormValidations";
import { state, actions, getters, STORE_NAMESPACES } from "@/store";

export default {
  components: {
    Button,
    Dialog,
    DataTable,
  },
  setup(props) {
    const vm = getCurrentInstance();
    const { APP, USERS, CLIENTES } = STORE_NAMESPACES;
        
    const IS_DEV_USER = getters[APP].getIsDevUser.value;
    const APP_DEBUG_MODE = APP_DEBUG || IS_DEV_USER;

    const headers = computed(() => {
      
      const headerArray = [
        { text: "Rol", value: "rol_user", sortable: false },
        { text: "Nombre", value: "nombre_completo" },
        // { text: "Apellidos", value: "apellidos" },
        { text: "Domicilio", value: "domicilio" },
        { text: "Localidad", value: "localidad" },
        { text: "C.P.", value: "cp" },
        { text: "Estatus", value: "estatus", sortable: false },
        { text: "Acciones", value: "action", sortable: false },
      ];

      if (logged_user.value.privilegio == 2) {
        headerArray.shift();
      }

      if (IS_DEV_USER) {
        headerArray.unshift(
          { text: "Id", value: "id", sortable: false }
        )
      }

      return headerArray;
    });

    const defaultValuesOnOpen = {};

    const logged_user = getters[USERS].findById(state[APP].idUser);
    const tableLoading = getters[APP].getLoading;
    const dataSource = getters[USERS].getAllNonAdmin;
    const clientesActivos = getters[CLIENTES].getActivos;
    const usuarioTable = useDataTable(dataSource, defaultValuesOnOpen);

    const validFormUsuario = ref(true);
    const contrasena = ref('');
    const showpwd = ref(false);
    const showDeleteInconsistencyDialog = ref(false);
    const userToDelete = ref("");

    const fullPageLoading = ref(false);

    const rolesSelect = [
      { text: 'Cobrador(a)', value: 3 },
      { text: 'Administrador(a)', value: 2 },
    ];

    /* Si se mandó un estatus de baja y tiene clientes relacionados, redirigir
    a la vista Clientes para corregir esos clientes y asignarles otro cobrador */
    function dadoDeBaja_TieneClientesRelacionados(user) {
      return clientesActivos.value
        .some(cliente => cliente.id_cobrador.includes(user.id) && !user.estatus);
    };

    function goToClientesAsociados() {
      vm.proxy.$root.$router.push({
        path: `/clientes`,
        query: { id_cobrador: btoaEncodeData(usuarioTable.editedItem.id) }
      });
    };

    // Resetear validacion cuando se abre el dialog/modal
    watch(() => usuarioTable.dialog, (dialog) => {
      if (dialog) {
        vm.proxy.$refs.form.resetValidation();
      }
      else {
        contrasena.value = '';
      }
    });

    function usuarioSave() {
      const usuarioData = {...usuarioTable.editedItem};

      usuarioTable.save(isEditable => {
        if (isEditable) {
          delete usuarioData.contrasena;

          if (contrasena.value.trim()) {
            usuarioData.contrasena = contrasena.value;
          }

          /* Estos sig. 2 datos no existen en la BD, sino que se componen cuando se traen 
          desde el servidor, por tanto no se necesita para la actualización */
          if (usuarioData.nombre_completo) {
            delete usuarioData.nombre_completo;
          }
          if (usuarioData.rol_user) {
            delete usuarioData.rol_user;
          }

          actions[USERS].UPDATE(usuarioData, (updatedUser) => {
            usuarioTable.close();

            if (dadoDeBaja_TieneClientesRelacionados(updatedUser)) {
              setTimeout(() => {
                vm.proxy.$root.$router.push({
                  path: `clientes`,
                  query: { user_action: "baja", id_cobrador: btoaEncodeData(usuarioData.id) }
                });
              }, 200);
            }
          });
        }
        else {
          // Datos para registrar log de usuario al insertar un nuevo usuario
          addUserLogToSendToDB(usuarioData, logged_user.value, "UserRegistro");

          usuarioData.contrasena = contrasena.value;
          actions[USERS].INSERT(usuarioData, () => usuarioTable.close());
        }

        APP_DEBUG_MODE && console.log("Datos user a enviar: ", usuarioData);
      });
    }

    function usuarioDelete(usuarioData) {
      if (dadoDeBaja_TieneClientesRelacionados(usuarioData)) {
        fullPageLoading.value = true;
        userToDelete.value = {...usuarioData};      

        setTimeout(() => {
          showDeleteInconsistencyDialog.value = true;
          fullPageLoading.value = false;
        }, 500);
        
        return;
      }
      else {
        userToDelete.value = "";  
        actions[USERS].DELETE(usuarioData, () => usuarioTable.close());
      }
    }

    function onDeleteInconsistencyGoToClientsView() {
      setTimeout(() => {
        vm.proxy.$root.$router.push({
          path: `clientes`,
          query: { user_action: "eliminar", id_cobrador: btoaEncodeData(userToDelete.value.id) }
        });
      }, 200);
    }

    return {
      logged_user,
      rolesSelect,
      contrasena,
      headers,
      usuarioTable,
      usuarioSave,
      validFormUsuario,
      tableLoading,
      showpwd,
      usuarioDelete,
      showDeleteInconsistencyDialog,
      onDeleteInconsistencyGoToClientsView,
      goToClientesAsociados,
      userToDelete,

      fullPageLoading,

      // Funcs. de validacion
      FormValidations,
    };
  }
};
</script>

